import styles from './Hero.module.scss';

import Buttons from '@/components/molecules/Buttons';
import Column from '@shared/components/atoms/Column';
import Container from '@shared/components/atoms/Container';
import DOMPurify from 'isomorphic-dompurify';
import Row from '@shared/components/atoms/Row';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import typeHero from '@/utilities/types/Hero';
import { Fragment } from 'react';
import Typography from '@shared/components/atoms/Typography';
import useProductLink from '@/utilities/hooks/useProductLink';
import { useLocale } from '@/utilities/contexts/Locale';
import { GetProductLinks } from '@/utilities/helpers/GetProductLinks';

const Chip = dynamic(() => import('@/components/atoms/Chip'));
const Image = dynamic(() => import('next/image'));

// ... Shared Package Components
const Product = dynamic(() => import('@shared/components/molecules/Product'));

interface HeroProps {
  hero: typeHero;
  index: number;
  onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const Hero = (props: HeroProps) => {
  const { hero, index, onLinkClick } = props;

  const locale = useLocale();

  return (
    <section
      className={cn(
        hero.media[0]?.background && styles[hero.media[0].background],
        styles.root,
      )}
      id={hero.id ? hero.id : `row-${index + 1}`}
    >
      {hero.media[0]?.cover && (
        <div
          className={cn(
            !hero.media[0].background && styles['is-pill'],
            styles.cover,
          )}
        >
          <Image
            alt={hero.media[0].cover.altText}
            height={hero.media[0].cover.mediaDetails.height || 520}
            priority
            src={hero.media[0].cover.mediaItemUrl}
            width={hero.media[0].cover.mediaDetails.width || 2246}
          />
        </div>
      )}
      <Container>
        <Row align="middle" xs={2}>
          <Column xs={12} lg={7}>
            <div className={styles.content}>
              {hero.content.map((content, index) => {
                const productLinks = content.product
                  ? GetProductLinks(content.product)
                  : null;

                return (
                  <Fragment key={index}>
                    {content.chip && (
                      <Chip className={styles.chip}>{content.chip}</Chip>
                    )}
                    {content.editor && (
                      <Typography className={styles.editor} xs={3} lg={4}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(content.editor),
                          }}
                        />
                      </Typography>
                    )}
                    {content.links && (
                      <Buttons buttons={content.links} onClick={onLinkClick} />
                    )}
                    {content.product && productLinks && (
                      <Product
                        className={styles.product}
                        product={content.product}
                        branchLinks={{
                          appStore: productLinks.appStoreLink,
                          googlePlay: productLinks.googlePlayLink,
                        }}
                        locale={locale}
                      />
                    )}
                    {content.title && (
                      <Typography
                        className={styles.title}
                        family="poppins"
                        tag="h1"
                        xs={7}
                        md={8}
                        lg={9}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(content.title),
                          }}
                        />
                      </Typography>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </Column>
          {hero.media[0]?.image && (
            <Column xs={12} lg={5}>
              <div className={styles.image}>
                <Image
                  alt={hero.media[0].image.altText}
                  height={hero.media[0].image.mediaDetails.height || 501}
                  priority
                  src={hero.media[0].image.mediaItemUrl}
                  width={hero.media[0].image.mediaDetails.width || 501}
                />
              </div>
            </Column>
          )}
        </Row>
        {hero.media[0]?.pill && hero.media[0]?.border && (
          <Column xs={12} lg={5}>
            <div
              className={cn(
                hero.media[0].border && styles[hero.media[0].border],
                styles.pill,
              )}
            >
              <Image
                alt={hero.media[0].pill.altText}
                height={hero.media[0].pill.mediaDetails.height || 520}
                priority
                src={hero.media[0].pill.mediaItemUrl}
                width={hero.media[0].pill.mediaDetails.width || 1040}
              />
            </div>
          </Column>
        )}
      </Container>
    </section>
  );
};

export default Hero;
